<template>
    <div>
        <vue-html2pdf
            :show-layout="false"
            :float-layout="true"
            :enable-download="false"
            :preview-modal="false"
            :paginate-elements-by-height="1400"
            filename="Analytics_Report"
            :pdf-quality="3"
            :manual-pagination="true"
            pdf-format="a4"
            :pdf-margin="10"
            pdf-orientation="landscape"
            pdf-content-width="1065px"
            @progress="onProgress($event)"
            @beforeDownload="beforeDownload($event)"
            ref="html2Pdf"
        >
            <section slot="pdf-content" class="bg-white">

                <section class="pdf-item">
                    <b-row v-if="organization_logo || site_logo">
                        <b-col cols="6">
                            <img v-if="site_logo" :src="site_logo" alt="Logo" width="110" height="110" loading="eager"/>
                        </b-col>
                        <b-col cols="6">
                            <img v-if="organization_logo" :src="organization_logo" alt="Logo" width="110" height="110" class="float-right" loading="eager"/>
                        </b-col>
                    </b-row>

                    <b-row>
                        <b-col cols="12" class="text-center"><strong><span style="color: #000 !important; font-size: 22px;">Feedback Analytics</span></strong></b-col>
                    </b-row>
                    <b-row style="color: #000 !important; font-size: 12px !important;" class="pb-2 pt-1">
                        <b-col cols="3" class="my-font"><strong>Project Site</strong></b-col>
                        <b-col cols="3" class="my-font">: {{ site_name }}</b-col>                    
                        <b-col cols="3" class="my-font"><strong>Location</strong></b-col>
                        <b-col cols="3" class="my-font">: {{location_name}}</b-col>

                        <b-col cols="3" class="my-font"><strong>Start Date</strong></b-col>
                        <b-col cols="3" class="my-font">: {{sitedateTime(start,date_format,'' )}}</b-col>
                        <b-col cols="3" class="my-font"><strong>End Date</strong></b-col>
                        <b-col cols="3" class="my-font">: {{sitedateTime(end,date_format,'' )}}</b-col>

                        <b-col cols="3" class="my-font"><strong>Generated By</strong></b-col>
                        <b-col cols="3" class="my-font">: {{this.$store.getters.currentUser.full_name}}</b-col>
                    </b-row>
                    <!-- <b-row class="mt-3" v-show="data.length > 0 && data[2].trigger_count > 0"> -->
                    <b-row class="mt-3" v-show="chart_type.includes('Analytic Table') || chart_type.includes('Analytic By Hours')">
                        <b-col :cols="chart_type.includes('Analytic Table') && chart_type.includes('Analytic By Hours') ? 6 : 12" class="" v-show="chart_type.includes('Analytic Table')">
                            <h4 class="text-center mt-2 mb-2 dark-text"><b>{{'Analytic Table'}}</b></h4>

                            <div class="text-center" style="overflow: hidden; border: 1px solid grey !important; border-radius: 8px; max-width: 570px; margin: auto;">
                                <table class="table table-bordered table-dark dark-text">
                                    <thead>
                                        <tr>
                                        <th scope="col"></th>
                                        <th scope="col">TRIGGER COUNT</th>
                                        <th scope="col">ATTEND TIME (AVERAGE)</th>
                                        <th scope="col">RESOLUTION</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <th scope="row">8am - 10pm</th>
                                            <td> {{ data[0].trigger_count }} </td>
                                            <td> {{ data[0].attend_time }} </td>
                                            <td> {{ data[0].resolution }} </td>
                                        </tr>
                                        <tr>
                                            <th scope="row">24 Hours</th>
                                            <td>{{ data[1].trigger_count }}</td>
                                            <td>{{ data[1].attend_time }}</td>
                                            <td>{{ data[1].resolution }}</td>
                                        </tr>
                                        <tr>
                                            <th scope="row">Total Impressions</th>
                                            <td colspan="3">{{ data[2].trigger_count }}</td>
                                        </tr>
                                    </tbody>  
                                </table>
                            </div>
                        </b-col>

                        <b-col :cols="chart_type.includes('Analytic Table') && chart_type.includes('Analytic By Hours') ? 6 : 12" v-show="chart_type.includes('Analytic By Hours')">
                            <h4 class="text-center mt-2 mb-1 dark-text"><b>{{'Analytic By Hours'}}</b></h4>
                            <b-row>
                                <b-col cols="6" class="pb-2">
                                    <div class="h-100">
                                        <h5 class="text-center mt-2 mb-2 dark-text"><b>{{'8am - 10pm'}}</b></h5>

                                        <vue-apex-charts
                                            type="pie"
                                            height="250"
                                            class="mt-0 mb-1"
                                            :options="optionsH1"
                                            :series="seriesH1"
                                            ref="pie_chartH1"
                                            v-if="(seriesH1[0] + seriesH1[1]) > 0"
                                        />
                                        <div class="row align-items-center text-light" style="height: 250px" v-if="(seriesH1[0] + seriesH1[1]) == 0"> 
                                            <div class="col-md-12">
                                                <h5 class="text-center dark-text">No Data Available</h5>
                                            </div>
                                        </div>
                                    </div>
                                </b-col>

                                <b-col cols="6" class="pb-2">
                                    <div class="h-100">
                                        <h5 class="text-center mt-2 mb-2 dark-text"><b>{{'24 Hours'}}</b></h5>

                                        <vue-apex-charts
                                            type="pie"
                                            height="250"
                                            class="mt-0 mb-1"
                                            :options="optionsH2"
                                            :series="seriesH2"
                                            ref="pie_chartH2"
                                            v-if="(seriesH2[0] + seriesH2[1]) > 0"
                                        />
                                        <div class="row align-items-center text-light" style="height: 250px" v-if="(seriesH2[0] + seriesH2[1]) == 0"> 
                                            <div class="col-md-12">
                                                <h5 class="text-center dark-text">No Data Available</h5>
                                            </div>
                                        </div>
                                    </div>
                                </b-col>
                            </b-row>
                        </b-col>

                    </b-row>
                </section>
                <div class="html2pdf__page-break" v-if="chart_type.includes('Analytic Table') || chart_type.includes('Analytic By Hours')" />

                <section class="pdf-item" v-show="chart_type.includes('Number of Feedback Received') || chart_type.includes('Nature Breakdown')">
                    <b-row>
                        <b-col :cols="chart_type.includes('Number of Feedback Received') && chart_type.includes('Nature Breakdown') ? 6 : 12" class="pb-2" v-show="chart_type.includes('Number of Feedback Received')">
                            <div class="doughnut h-100">    

                                <h4 class="text-center mt-2 mb-2 dark-text"><b>{{'Number of Feedback Received'}}</b></h4>

                                <vue-apex-charts
                                    ref="doughnut_chart"
                                    type="donut"
                                    height="400"
                                    :options="chartOptionsNFR"
                                    :series="seriesNFR"
                                    v-if="seriesNFR.reduce((partialSum, a) => partialSum + a, 0) > 0"
                                />
                                <div class="row align-items-center text-light" style="height: 420px" v-if="seriesNFR.reduce((partialSum, a) => partialSum + a, 0) == 0"> 
                                    <div class="col-md-12">
                                        <h5 class="text-center dark-text">No Data Available</h5>
                                    </div>
                                </div>
                            </div>
                        </b-col>
                        
                        <b-col :cols="chart_type.includes('Number of Feedback Received') && chart_type.includes('Nature Breakdown') ? 6 : 12" class="pb-2" v-show="chart_type.includes('Nature Breakdown')">
                            <div class="doughnut h-100">    

                                <h4 class="text-center mt-2 mb-2 dark-text"><b>{{'Nature Breakdown'}}</b></h4>

                                <vue-apex-charts
                                    ref="nbd_chart"
                                    type="pie"
                                    height="400"
                                    class="mt-0 mb-1"
                                    :options="optionsNBD"
                                    :series="seriesNBD"
                                    v-show="showNBD"
                                />
                                <div class="row align-items-center text-light" style="height: 420px" v-show="showNBD == false"> 
                                    <div class="col-md-12">
                                        <h5 class="text-center dark-text">No Data Available</h5>
                                    </div>
                                </div>
                            </div>
                        </b-col>

                    </b-row>
                </section>
                <div class="html2pdf__page-break" v-if="chart_type.includes('Number of Feedback Received') || chart_type.includes('Nature Breakdown')"/>
                
                <section class="pdf-item" v-show="chart_type.includes('Nature of Trigger')">
                    <b-row>
                        <!-- Nature of Trigger -->
                        <b-col cols="12" class="pb-2">
                            <div>
                                <h4 class="text-center mt-2 mb-2 dark-text"><b>{{'Nature of Trigger'}}</b></h4>
                                <vue-apex-charts
                                    ref="bar_chartNT"
                                    type="bar"
                                    height="400"
                                    :options="optionsNT"
                                    :series="seriesNT"
                                    v-show="showNT"
                                />
                                <div class="row align-items-center text-light" style="height: 420px" v-show="showNT == false"> 
                                    <div class="col-md-12">
                                        <h5 class="text-center dark-text">No Data Available</h5>
                                    </div>
                                </div>
                            </div>
                        </b-col>
                    </b-row>
                </section>

                <div class="html2pdf__page-break" v-if="chart_type.includes('Nature of Trigger')"/>
                
                <section class="pdf-item" v-show="chart_type.includes('Trigger By Locations')">
                    <b-row>
                        <b-col cols="12" class="pb-2">
                            <div>
                                <h4 class="text-center mt-2 mb-2 dark-text"><b>{{'Trigger By Locations'}}</b></h4>
                                <vue-apex-charts
                                    ref="loc_chart"
                                    type="bar"
                                    height="400"
                                    :options="optionsTBL"
                                    :series="seriesTBL"
                                    v-show="showTBL"
                                />
                                <div class="row align-items-center text-light" style="height: 420px" v-show="showTBL == false"> 
                                    <div class="col-md-12">
                                        <h5 class="text-center dark-text">No Data Available</h5>
                                    </div>
                                </div>
                            </div>
                        </b-col>
                    </b-row>
                </section>

                <div class="html2pdf__page-break" v-if="chart_type.includes('Trigger By Locations')"/>

                <section class="pdf-item" v-show="chart_type.includes('Daily Representation')">
                    <b-row>
                        <!-- Daily Representation -->
                        <b-col cols="12" offset-md="0" class="pb-2">
                            <div title="">
                                <h4 class="text-center mt-2 mb-2 dark-text">{{'Daily Representation'}}</h4>
                                <vue-apex-charts
                                    ref="line_chartDR"
                                    type="area"
                                    height="400"
                                    :options="chartOptionsDR"
                                    :series="seriesDR"
                                    v-show="showDR"
                                />
                                <div class="row align-items-center text-light" style="height: 420px" v-show="showDR == false"> 
                                    <div class="col-md-12">
                                        <h5 class="text-center dark-text">No Data Available</h5>
                                    </div>
                                </div>
                            </div>
                        </b-col>
                    </b-row>
                </section>

                <div class="html2pdf__page-break" v-if="chart_type.includes('Daily Representation')"/>
                
                <section class="pdf-item" v-show="chart_type.includes('Graphical Chart')">
                    <b-row>
                        <!-- Graphical Chart -->
                        <b-col cols="12" offset-md="0" class="pb-2">
                            <div>
                                <h4 class="text-center mt-2 mb-2 dark-text"><b>{{'Graphical Chart'}}</b></h4>
                                
                                <b-row v-if="graphicalData && graphicalData.length > 0">

                                    <b-col cols="3" class="text-center pt-2" v-for="(gd, ind) in graphicalData" :key="ind">
                                        <div style="border: 2px solid grey; border-radius:8px; page-break-inside: avoid !important;" class="pb-2">
                                            <ChartComponentPDF :graphical="{data: gd.percentage, image: gd.image, color: gd.color}"/>
                                            <span class="text-center" style="color: #000 !important; font-size: 12px !important;">{{gd.label}} - {{gd.data}}</span>
                                        </div>
                                    </b-col>

                                </b-row>
                                <div class="row align-items-center text-light" style="height: 420px" v-else> 
                                    <div class="col-md-12">
                                        <h5 class="text-center dark-text">No Data Available</h5>
                                    </div>
                                </div>
                            </div>
                        </b-col>
                    </b-row>
                    <!-- <b-row v-if="main_logo" style="bottom: 0; position: absolute">
                        <b-col cols="12">
                            <img v-if="main_logo" :src="main_logo" alt="Logo" width="110" loading="eager"/>
                        </b-col>
                    </b-row> -->
                </section>

            </section>
        </vue-html2pdf>
    </div>
</template>

<script>
    import {
		BCard, BRow, BCol, BFormInput, BButton, BTable, BMedia, BAvatar, BLink,BAlert,BFormGroup, BBadge, BDropdown, BDropdownItem, 
        BPagination, BFormCheckbox, BCardBody, BFormSelectOption, BFormSelect, BFormTextarea, BForm, BFormRadio, 
        BBreadcrumb, BFormRadioGroup, BTabs, BTab
	} from 'bootstrap-vue';
    import { POST_API } from "../store/actions.type";
    import moment from "moment-timezone";
	import VueApexCharts from 'vue-apexcharts';
    import VueHtml2pdf from 'vue-html2pdf';
	import Bus from "../event-bus";
    import { $themeColors } from '@themeConfig'
    import ChartComponentPDF from './client/analytics/ChartComponentPDF';

    export default {
        name: 'Export',
		components: {
			BCardBody, BCard, BRow, BCol, BFormInput, BButton, BTable, BMedia, BAvatar, BLink, BBadge, BDropdown, BDropdownItem, 
            BPagination, BFormCheckbox, BFormSelect, BFormSelectOption, VueApexCharts, BFormTextarea, BAlert, BFormGroup, BForm, 
			BFormRadio, BBreadcrumb, VueHtml2pdf, BFormRadioGroup, BTabs, BTab, ChartComponentPDF
		},

		data() {
			return {
				organization_logo : '',
                site_logo : '',
                main_logo : '',

                site : null,
                location : null,
                start : '',
                end : '',
                site_name : '',
                location_name : '',
                date_format : '',
                chart_type : [],

                // table vars 
                tableColumns: [
                    { key: 'legend', label: '', sortable: false , thStyle:  {width: '25%'}},
                    { key: 'trigger_count', label: 'Trigger Count', sortable: false , thStyle:  {width: '25%'}},
                    { key: 'attend_time', label: 'Attend Time (Average)', sortable: false , thStyle:  {width: '25%'}},
                    { key: 'resolution', label: 'Resolution', sortable: false , thStyle:  {width: '25%'}},
                ],
                items  : [],
                webUrl : process.env.VUE_APP_SERVER_URL,
                data : [
                    {
                        legend: '8am - 10pm',
                        trigger_count: '0',
                        attend_time: '0',
                        resolution: '0%'
                    }, {
                        legend: '24 Hours',
                        trigger_count: '0',
                        attend_time: '0',
                        resolution: '0%'
                    }, {
                        legend: 'Total Impressions',
                        trigger_count: '0%'
                    }
                ],

                // Hourly 1
                themeColor: '#FFFFFF',
                seriesH1:[],
                optionsH1: {
                    chart: {
                        toolbar: {
                            show: false,
                        },
                        animations: {
                            enabled: false
                        }
                    },
                    labels: [],

                    dataLabels: {
                        enabled: true,
                    },
                    legend: {
                        show: true,
                        position: 'bottom',
                        fontFamily: 'Verdana',
                        labels: {
                            colors: ['#000'],
                        },
                    },
                    stroke: {
                        width: 0,
                    },
                    colors: [$themeColors.danger, $themeColors.success, $themeColors.primary, $themeColors.info, $themeColors.warning, $themeColors.secondary, $themeColors.light, $themeColors.dark],
                },

                // Hourly 2
                seriesH2:[],
                optionsH2: {
                    chart: {
                        toolbar: {
                            show: false,
                        },
                        animations: {
                            enabled: false
                        }
                    },
                    labels: [],

                    dataLabels: {
                        enabled: true,
                    },
                    legend: {
                        show: true,
                        position: 'bottom',
                        fontFamily: 'Verdana',
                        labels: {
                            colors: ['#000'],
                        },
                    },
                    stroke: {
                        width: 0,
                    },
                    colors: [$themeColors.danger, $themeColors.success, $themeColors.primary, $themeColors.info, $themeColors.warning, $themeColors.secondary, $themeColors.light, $themeColors.dark],
                },

                // Number Of Feedback Received
                showNFR:false,
                seriesNFR: [],
                chartOptionsNFR: {
                    labels: ['Satisfied', 'Unsatisfied'],
                    stroke: {
                        width: 0,
                        colors: undefined
                    },
                    chart: {
                        type: 'donut',
                        animations: {
                            enabled: false
                        }
                    },
                    colors: [$themeColors.success, $themeColors.danger, $themeColors.primary, $themeColors.info, $themeColors.secondary],
                    legend: {
                        position: 'bottom',
                        fontFamily: 'Verdana',
                        labels: {
                            colors: ['#000000'],
                        },
                    },
                    dataLabels: { 
                        enabled: true,
                        formatter: function (val, opt) {
                            return Number((val).toFixed(2))+"%"
                        }
                    },
                    plotOptions: {
                        pie: {
                            donut: {
                                labels: {
                                    show: true,
                                    name: {
                                        show: true,
                                        fontSize: '22px',
                                        fontFamily: 'Verdana',
                                        color: '#000000',
                                        offsetY: -10
                                    },
                                    value: {
                                        show: true,
                                        fontFamily: 'Verdana',
                                        color: '#000000',
                                        fontSize: '25px',
                                        fontWeight: 'bold',
                                        offsetY: 16,
                                        formatter: function (val) {
                                            return val
                                        }
                                    },
                                    total: {
                                        show: true,
                                        label: 'Total',
                                        color: '#000000',
                                        fontSize: '30px',
                                        formatter: function (w) {
                                            return w.globals.seriesTotals.reduce((a, b) => {
                                                return a + b
                                            }, 0)
                                        }
                                    }
                                }
                            }
                        }
                    },
                },

                // Nature BreakDown
                showNBD:false,
                seriesNBD:[],
                optionsNBD: {
                    chart: {
                        toolbar: {
                            show: false,
                        },
                        animations: {
                            enabled: false
                        }
                    },
                    labels: [],

                    dataLabels: {
                        enabled: true,
                    },
                    legend: {
                        show: true,
                        position: 'bottom',
                        fontFamily: 'Verdana',
                        labels: {
                            colors: ['#000'],
                        },
                    },
                    stroke: {
                        width: 0,
                    },
                    colors: [$themeColors.danger, $themeColors.success, $themeColors.primary, $themeColors.info, $themeColors.warning, $themeColors.secondary, $themeColors.light, $themeColors.dark],
                },

                // Nature of Trigger
                showNT:false,
                seriesNT: [
                    {
                        data: []
                    }
                ],
                optionsNT: {
                    chart: {
                        toolbar: {
                            show: false,
                        },
                        animations: {
                            enabled: false
                        }
                    },
                    colors: ['#ffffff','#000000'],
                    plotOptions: {
                        bar: {
                            horizontal: true,
                            barHeight: '60%',
                            // endingShape: 'rounded',
                            borderRadius: 3,
                            borderRadiusApplication: 'end'
                        },
                    },
                    grid: {
                        xaxis: {
                            lines: {
                                show: false,
                            },
                            labels: {
                                show: true,
                                style: {
                                    colors: ['#000000'],
                                }
                            }
                        },
                        yaxis: {
                            lines: {
                                show: false,
                            },
                            labels: {
                                show: true,
                                style: {
                                    colors: ['#000000'],
                                }
                            }
                        }
                    },
                    dataLabels: {
                        enabled: false,
                        textAnchor: 'start',
                        offsetY: 2,
                        style: {
                            fontSize: "7px",
                            colors: [$themeColors.dark]
                        },
                        formatter: function (val, opt) {
                            return val > 0 ? /* opt.w.globals.labels[opt.dataPointIndex] + ":  " +  */val : ''
                        },
                    },
                    xaxis: {
                        categories: [],
                        tickAmount:1,
                        labels: {
                            show: true,
                            style: {
                                colors: '#202124',
                            },
                            type: 'numeric',
                            formatter: function(val) {
                                return parseInt(val).toFixed(0)
                            },
                        }
                    },
                    yaxis: {
                        labels: {
                            show: true,
                            style: {
                                colors: ['#202124', '#202124', '#202124', '#202124', '#202124', '#202124', '#202124', '#202124', '#202124', '#202124', '#202124', '#202124', '#202124', '#202124', '#202124', '#202124', '#202124', '#202124', '#202124', '#202124', '#202124', '#202124','#202124','#202124','#202124','#202124','#202124','#202124','#202124'],
                            },
                        }
                    },
                    legend: {
                        show: true,
                        showForSingleSeries: true,
                        position: 'bottom',
                        fontFamily: 'Verdana',
                        labels: {
                            colors: ['#202124'],
                        },
                    },
                },

                // Trigger By Locations
                showTBL:false,
                seriesTBL: [
                    {
                        data: []
                    }
                ],
                optionsTBL: {
                    chart: {
                        toolbar: {
                            show: false,
                        },
                        animations: {
                            enabled: false
                        }
                    },
                    colors: ['#ffffff','#000000'],
                    plotOptions: {
                        bar: {
                            horizontal: true,
                            barHeight: '60%',
                            // endingShape: 'rounded',
                            borderRadius: 4,
                            borderRadiusApplication: 'end'
                        },
                    },
                    grid: {
                        xaxis: {
                            lines: {
                                show: false,
                            },
                            labels: {
                                show: true,
                                style: {
                                    colors: [$themeColors.secondary],
                                }
                            }
                        },
                        yaxis: {
                            lines: {
                                show: false,
                            },
                            labels: {
                                show: true,
                                style: {
                                    colors: [$themeColors.secondary],
                                }
                            }
                        }
                    },
                    dataLabels: {
                        enabled: false,
                        textAnchor: 'start',
                        style: {
                            colors: [$themeColors.dark]
                        },
                        formatter: function (val, opt) {
                            return val > 0 ? /* opt.w.globals.labels[opt.dataPointIndex] + ":  " +  */val : ''
                        },
                    },
                    xaxis: {
                        categories: [],
                        tickAmount:1,
                        labels: {
                            show: true,
                            style: {
                                colors: '#000000',
                            },
                            type: 'numeric',
                            formatter: function(val) {
                                return parseInt(val).toFixed(0)
                            },
                        }
                    },
                    yaxis: {
                        labels: {
                            show: true,
                            style: {
                                colors: ['#000000', '#000000', '#000000', '#000000', '#000000', '#000000', '#000000', '#000000', '#000000', '#000000', '#000000', '#000000', '#000000', '#000000', '#000000', '#000000', '#000000', '#000000', '#000000', '#000000', '#000000', '#000000','#000000','#000000','#000000','#000000','#000000','#000000','#000000'],
                            },
                        }
                    },
                    
                    tooltip: {
                    theme: 'dark',
                        x: {
                            show: false
                        },
                        y: {
                            title: {
                                formatter: function (val) {
                                    return ''
                                }
                            }
                        }
                        
                    },
                    legend: {
                        show: true,
                        showForSingleSeries: true,
                        position: 'bottom',
                        fontFamily: 'Verdana',
                        labels: {
                            colors: ['#000000'],
                        },
                    },
                },

                // Daily Representation
                showDR:false,
                seriesDR: [{
                    name: 'Satisfied',
                    data: [],
                }, {
                    name: 'Unsatisfied',
                    data: [],
                }],
                chartOptionsDR: {
                    chart: {
                        toolbar: {
                            show: false
                        },
                        animations: {
                            enabled: false
                        }
                    },
                    dataLabels: {
                        enabled: false,
                    },
                    stroke: {
                        show: false,
                        curve: 'straight',
                    },
                    legend: {
                        show: true,
                        position: 'bottom',
                        horizontalAlign: 'center',
                        fontSize: '14px',
                        fontFamily: 'Verdana',
                        labels: {
                            colors: ['#000000'],
                        },
                    },
                    grid: {
                        xaxis: {
                            lines: {
                                show: false,
                            },
                            labels: {
                                show: true,
                                style: {
                                    colors: [$themeColors.secondary],
                                }
                            }
                        },
                        yaxis: {
                            lines: {
                                show: false,
                            },
                            labels: {
                                show: true,
                                style: {
                                    colors: [$themeColors.secondary],
                                }
                            }
                        },
                    },
                    xaxis: {
                        categories: [],

                        labels: {
                            show: true,
                            style: {
                                colors: '#000000',
                            }
                        }
                    },
                    yaxis: {
                        tickAmount:1,
                        labels: {
                            show: true,
                            style: {
                                colors: ['#000000'],
                            },
                            type: 'numeric',
                            formatter: function(val) {
                                return parseInt(val).toFixed(0)
                            }
                        }
                    },
                    fill: {
                        opacity: 1,
                        type: 'solid',
                    },
                    tooltip: {
                    theme: 'dark',
                        x: {
                            show: false
                        },
                        y: {
                            title: {
                            formatter: function (val) {
                                return ''
                            }

                            }
                        }
                        
                    },
                    colors: [$themeColors.success, $themeColors.danger, $themeColors.primary, $themeColors.warning, $themeColors.info],
                },

                // Graphical Chart 
                graphicalData : null
			}
		},
		methods : {			
			// Export PDF functions
            exportPDF() {
                 Swal.fire({
                    title: 'Please Wait',
                    html: 'PDF is Generating.',
                    showConfirmButton: false,
                    allowOutsideClick: false,
                    allowEscapeKey: false,
                    didOpen: () => {
                        Swal.showLoading()
                    },
                }); 

                //Bus.$emit('showLoader');
                this.getHeaderImages().then(() => {
                    this.dataList().then(() => {
                        this.updateSeriesH1().then(() => {
                            this.updateSeriesH2().then(() => {
                                this.updateSeriesNFR().then(() => {
                                    this.updateSeriesNBD().then(() => {
                                        this.updateSeriesNT().then(() => {
                                            this.updateSeriesTBL().then(() => {
                                                this.updateSeriesDR().then(() => {
                                                    this.updateSeriesGC().then(() => {
                                                        var time = this.chart_type.includes('Graphical Chart') ? 10000 : 7000;
                                                        setTimeout(()=>{
                                                            this.$refs.html2Pdf.generatePdf();
                                                        }, time);
                                                        /* window.addEventListener("load", function(event) {
                                                            console.log('sasasass ')
                                                            this.$refs.html2Pdf.generatePdf();
                                                        }); */
                                                    });                 
                                                });                 
                                            });                 
                                        });
                                    });
                                });
                            });
                        });
                    })
                })
            },

            onProgress(event) {
                /* if(event == 100){
                    // Swal.fire({
                    //     position : 'center',
                    //     icon     : 'success',
                    //     title    : 'PDF Downloaded.',
                    //     timer    : 1500,
                    //     showConfirmButton : false
                    // });
                    Bus.$emit('hideLoader');
                    window.close()
                } */
            },

            async beforeDownload ({ html2pdf, options, pdfContent }) {
                options.margin = [0.5, 0.3, 0.6, 0.3]; // values for [top, left, bottom, right]
                options.useCORS = true;
                
                await html2pdf()
                .set(options)
                .from(pdfContent)
                .toPdf()
                .get('pdf')
                .then((pdf) => {
                    const totalPages = pdf.internal.getNumberOfPages()
                    for (let i = 1; i <= totalPages; i++) {
                        pdf.setPage(i)
                        pdf.setFontSize(10)
                        pdf.setTextColor(150)
                        pdf.text('Report generated by ELTaskforce.', (pdf.internal.pageSize.getWidth() * 0.05), (pdf.internal.pageSize.getHeight() - 0.2))
                        pdf.text('Page ' + i + ' of ' + totalPages, (pdf.internal.pageSize.getWidth() * 0.88), (pdf.internal.pageSize.getHeight() - 0.2))
                    }
                }).save();

                setTimeout(()=>{
                    window.close()
                }, 2000);
            },

            /* async beforeDownload ({ html2pdf, options, pdfContent }) {
                // options.margin = .3;
                options.margin = [0.5, 0.3, 0.6, 0.3]; // values for [top, left, bottom, right]
                options.useCORS = true;
                // options.html2canvas = { scale: 2,useCORS: true };
                // options.enableLinks = true;

                // options.image = { type: "jpeg", quality: 0.98 };
                // options.html2canvas = { letterRendering: true };
                // options.jsPDF = { unit: "in", format: "a4", orientation: "landscape" };

                // options.image = { type: 'jpeg', quality: 1 };

                // await html2pdf().set(options);
                var pdfHTML = await html2pdf()
                .set(options)
                .from(pdfContent)
                .toPdf()
                .get('pdf');

                const totalPages = pdfHTML.internal.getNumberOfPages()
                
                for (let i = 1; i <= totalPages; i++) {
                    pdfHTML.setPage(i)
                    pdfHTML.setFontSize(10)
                    pdfHTML.setTextColor(150)
                    pdfHTML.text('Report generated by ELTaskforce.', (pdfHTML.internal.pageSize.getWidth() * 0.05), (pdfHTML.internal.pageSize.getHeight() - 0.2))
                    pdfHTML.text('Page ' + i + ' of ' + totalPages, (pdfHTML.internal.pageSize.getWidth() * 0.88), (pdfHTML.internal.pageSize.getHeight() - 0.2))
                }

                pdfHTML.save();
                
                // .then((pdf) => {
                //     // console.log(pdf);
                // }).save();

                // console.log(options)

                // await html2pdf().set(options)

                // html2pdf().set(options).from(this.$refs.html2Pdf).save();
                
                // html2pdf().set(options).from(this.$refs.html2Pdf).toPdf().get('pdf').then((pdf) => {
                //     const totalPages = pdf.internal.getNumberOfPages()
                //     for (let i = 1; i <= totalPages; i++) {
                //         pdf.setPage(i)
                //         pdf.setFontSize(10)
                //         pdf.setTextColor(150)
                //         pdf.text('Page ' + i + ' of ' + totalPages, (pdf.internal.pageSize.getWidth() * 0.88), (pdf.internal.pageSize.getHeight() - 0.3))
                //     }
                // }).save();
            }, */

            // async beforeDownload({ html2pdf, options, pdfContent }) {
            //     options.margin = [0.5, 0.3, 0.6, 0.3];
            //     options.useCORS = true;

            //     await html2pdf()
            //         .set(options)
            //         .from(pdfContent)
            //         .toPdf()
            //         .get('pdf')
            //         .then((pdf) => {

            //             console.log(pdf)
            //             const totalPages = pdf.internal.getNumberOfPages();
            //             console.log('totalPages >>>>>>>>> ', totalPages)

            //             if(totalPages > 0){
            //                 for (let i = 1; i <= totalPages; i++) {
            //                     pdf.setPage(i);
            //                     pdf.setFontSize(10);
            //                     pdf.setTextColor(150);
            //                     pdf.text('Report generated by ELTaskforce.', pdf.internal.pageSize.getWidth() * 0.05, pdf.internal.pageSize.getHeight() - 0.2);
            //                     pdf.text('Page ' + i + ' of ' + totalPages, pdf.internal.pageSize.getWidth() * 0.88, pdf.internal.pageSize.getHeight() - 0.2);
            //                 }
    
            //                 // Call save() only once after adding the footer on all pages
            //                 console.log('after----->>>>> ', pdf)
            //                 pdf.save();
            //             }
            //         });
            // },

            getHeaderImages(){
                if(this.site != 'all-site'){
                    return this.$store.dispatch(POST_API, {
                        data:{
                            site : this.site ? this.site : null
                        },
                        api: '/api/feedback-panel-logo'
                    })
                    .then(() => {
                        if (this.$store.getters.containsErrors) {
                            this.error_message = this.$store.getters.getErrors;
                        } 
                        else {
                            this.organization_logo = this.$store.getters.getResults.data.organization_logo;
                            this.site_logo = this.$store.getters.getResults.data.site_logo;
                            this.main_logo = this.$store.getters.getResults.data.main_logo;
                        }
                    });
                }
            },

            // table get data
            dataList(){
                return this.$store.dispatch(POST_API, {
                    data:{
                        site   	 : this.site ? this.site : null,
					    location : this.location ? this.location : null,
                        start    : moment(this.start).format('YYYY-MM-DD'),
					    end      : moment(this.end).format('YYYY-MM-DD'),
                        role     : this.$store.getters.currentUser.role,
                        om_sites : this.$store.getters.currentUser.om_sites,
                        report   : 'periodic',
                    },
                    api: '/api/feedback-request-analytics-table'
                })
                .then(() => {
                    if (this.$store.getters.containsErrors) {
                        this.error_message = this.$store.getters.getErrors;
                    } else {
                        this.data = this.$store.getters.getResults.data;
                        return this.data;
                    }
                });
            },

            // Hourly 1
            updateSeriesH1() {
                return this.$store.dispatch(POST_API, {
                    data:{
                        site   	 : this.site ? this.site : null,
                        location : this.location ? this.location : null,
                        start    : moment(this.start).format('YYYY-MM-DD'),
                        end      : moment(this.end).format('YYYY-MM-DD'),
                        role     : this.$store.getters.currentUser.role,
                        om_sites : this.$store.getters.currentUser.om_sites,
                        report   : 'periodic',
                    },
                    api: '/api/feedback-request-analytics-pie-8-10'
                })
                .then(() => {
                    if (this.$store.getters.containsErrors) {
                        this.error_message = this.$store.getters.getErrors;
                    } else {
                        this.optionsH1.labels = this.$store.getters.getResults.data.labels
                        this.seriesH1 = this.$store.getters.getResults.data.series;
                        
                        this.optionsH1.colors = [$themeColors.warning, $themeColors.success];
                        if(typeof this.$refs['pie_chartH1'] != 'undefined'){
                            this.$refs.pie_chartH1.updateSeries(this.seriesH1, true);
                            this.$refs.pie_chartH1.updateOptions(this.optionsH1, true);    
                        }
                    }
                });
            },

            // Hourly 2
            updateSeriesH2() {
                return this.$store.dispatch(POST_API, {
                    data:{
                        site   	 : this.site ? this.site : null,
                        location : this.location ? this.location : null,
                        start    : moment(this.start).format('YYYY-MM-DD'),
                        end      : moment(this.end).format('YYYY-MM-DD'),
                        role     : this.$store.getters.currentUser.role,
                        om_sites : this.$store.getters.currentUser.om_sites,
                        report   : 'periodic',
                    },
                    api: '/api/feedback-request-analytics-pie-24'
                })
                .then(() => {
                    if (this.$store.getters.containsErrors) {
                        this.error_message = this.$store.getters.getErrors;
                    } else {
                        this.optionsH2.labels = this.$store.getters.getResults.data.labels
                        this.seriesH2 = this.$store.getters.getResults.data.series;
                        
                        this.optionsH2.colors = [$themeColors.primary, $themeColors.info];
                        if(typeof this.$refs['pie_chartH2'] != 'undefined'){
                            this.$refs.pie_chartH2.updateSeries(this.seriesH2, true);
                            this.$refs.pie_chartH2.updateOptions(this.optionsH2, true);    
                        }                        
                    }
                });
            },

            // Number Of Feedback Received
            updateSeriesNFR() {
                return this.$store.dispatch(POST_API, {
                    data:{
                        site   	 : this.site ? this.site : null,
                        location : this.location ? this.location : null,
                        start    : moment(this.start).format('YYYY-MM-DD'),
                        end      : moment(this.end).format('YYYY-MM-DD'),
                        role     : this.$store.getters.currentUser.role,
                        om_sites : this.$store.getters.currentUser.om_sites,
                        report   : 'periodic',
                    },
                    api: '/api/feedback-request-analytics-doughnut'
                })
                .then(() => {
                    if (this.$store.getters.containsErrors) {
                        this.error_message = this.$store.getters.getErrors;
                    } else {
                        this.seriesNFR[0] = parseInt(this.$store.getters.getResults.data[0].value);
                        this.seriesNFR[1] = parseInt(this.$store.getters.getResults.data[1].value);
                        
                        if (typeof this.$refs['doughnut_chart'] != 'undefined') {
                            
                            this.$refs.doughnut_chart.updateSeries(this.seriesNFR, true);
                            this.$refs.doughnut_chart.updateOptions(this.chartOptionsNFR, true);
                            if ((this.seriesNFR[0] + this.seriesNFR[1]) > 0) {
                                this.showNFR = true;
                            }else {
                                this.showNFR = false;
                            }
                        }
                    }
                });
            },

            // Nature BreakDown
            updateSeriesNBD() {
                return this.$store.dispatch(POST_API, {
                    data:{
                        site   	 : this.site ? this.site : null,
                        location : this.location ? this.location : null,
                        start    : moment(this.start).format('YYYY-MM-DD'),
                        end      : moment(this.end).format('YYYY-MM-DD'),
                        role     : this.$store.getters.currentUser.role,
                        om_sites : this.$store.getters.currentUser.om_sites,
                        report   : 'periodic',
                    },
                    api: '/api/feedback-request-analytics-bar'
                })
                .then(() => {
                    if (this.$store.getters.containsErrors) {
                        this.error_message = this.$store.getters.getErrors;
                    } else {

                        this.optionsNBD.labels = this.$store.getters.getResults.data.labels
                        this.seriesNBD = this.$store.getters.getResults.data.series;

                        this.optionsNBD.colors = [];

                        if (this.seriesNBD && this.seriesNBD.length > 0) {

                            for (var i = 0; i < (this.seriesNBD.length); i++) {
                            
                                var randomColor = Math.floor(Math.random()*16777215).toString(16);
                                
                                if (randomColor.length == 6) {                        
                                    this.optionsNBD.colors.push('#'+randomColor);
                                }else{
                                    i = i - 1;
                                }
                            }    
                        }

                        if (this.optionsNBD.labels.length > 0) {
                            this.showNBD = true;
                            this.$refs.nbd_chart.updateSeries(this.seriesNBD, true);
                            this.$refs.nbd_chart.updateOptions(this.optionsNBD, true);
                        }else{
                            this.showNBD = false;
                        }
                        
                    }
                });
            },

            // Nature of Trigger
            updateSeriesNT() {
                return this.$store.dispatch(POST_API, {
                    data:{
                        site   	 : this.site ? this.site : null,
                        location : this.location ? this.location : null,
                        start    : moment(this.start).format('YYYY-MM-DD'),
                        end      : moment(this.end).format('YYYY-MM-DD'),
                        role     : this.$store.getters.currentUser.role,
                        om_sites : this.$store.getters.currentUser.om_sites,
                        report   : 'periodic',
                        toilet   : this.toilet
                    },
                    api: '/api/feedback-request-analytics-bar-3'
                })
                .then(() => {
                    if (this.$store.getters.containsErrors) {
                        this.error_message = this.$store.getters.getErrors;
                    } else {
                        this.seriesNT = this.$store.getters.getResults.data.series;
                        this.optionsNT.xaxis.categories = this.$store.getters.getResults.data.labels;
    
                        this.optionsNT.colors = [$themeColors.info, $themeColors.danger, $themeColors.success, $themeColors.primary, $themeColors.warning]

                        this.optionsNT.xaxis.labels.style.colors = '#000000'
                        
                        if (this.$store.getters.getResults.data.labels.length > 0) {
    
                            this.showNT = true;
                        }else{
                            this.showNT = false;
                        }    
                        this.$refs.bar_chartNT.updateSeries(this.seriesNT, true);
                        this.$refs.bar_chartNT.updateOptions(this.optionsNT, true);
                        
                    }
                });
            },

            // Trigger by Locations
            updateSeriesTBL() {
                return this.$store.dispatch(POST_API, {
                    data:{
                        site   	 : this.site ? this.site : null,
                        location : this.location ? this.location : null,
                        start    : moment(this.start).format('YYYY-MM-DD'),
                        end      : moment(this.end).format('YYYY-MM-DD'),
                        role     : this.$store.getters.currentUser.role,
                        om_sites : this.$store.getters.currentUser.om_sites,
                        report   : 'periodic',
                        toilet   : this.toilet
                    },
                    api: '/api/feedback-request-toilet-analytics'
                })
                .then(() => {
                    if (this.$store.getters.containsErrors) {
                        this.error_message = this.$store.getters.getErrors;
                    } else {
                        this.seriesTBL = this.$store.getters.getResults.data.series;
                        this.optionsTBL.xaxis.categories = this.$store.getters.getResults.data.labels;
    
                        this.optionsTBL.colors = [$themeColors.success,$themeColors.warning,$themeColors.info,$themeColors.primary]
                        
                        if (this.$store.getters.getResults.data.labels.length > 0) {
    
                            this.showTBL = true;
                        }else{
                            this.showTBL = false;
                        }
    
                        this.$refs.loc_chart.updateSeries(this.seriesTBL, true);
                        this.$refs.loc_chart.updateOptions(this.optionsTBL, true);
                        
                    }
                });
            },

            // Daily Representation
            updateSeriesDR() {
                return this.$store.dispatch(POST_API, {
                    data:{
                        site   	 : this.site ? this.site : null,
                        location : this.location ? this.location : null,
                        start    : moment(this.start).format('YYYY-MM-DD'),
                        end      : moment(this.end).format('YYYY-MM-DD'),
                        role     : this.$store.getters.currentUser.role,
                        om_sites : this.$store.getters.currentUser.om_sites,
                        report   : 'periodic',
                    },
                    api: '/api/feedback-request-analytics-line-area'
                })
                .then(() => {
                    if (this.$store.getters.containsErrors) {
                        this.error_message = this.$store.getters.getErrors;
                    } else {
                        this.chartOptionsDR.xaxis.categories = this.$store.getters.getResults.data.categories;
                        this.seriesDR[0].data = this.$store.getters.getResults.data.happy;
                        this.seriesDR[1].data = this.$store.getters.getResults.data.unhappy;
    
                        this.showDR = false;
                        var temparr = [];
                        temparr = temparr.concat(this.$store.getters.getResults.data.happy,this.$store.getters.getResults.data.unhappy);
                        
                        for (var i = 0; i < temparr.length; i++) {
                            
                            if (temparr[i] > 0) {
                                this.showDR = true;
                                break;
                            }
                        }
    
                        this.$refs.line_chartDR.updateSeries(this.seriesDR, true);
                        this.$refs.line_chartDR.updateOptions(this.chartOptionsDR, true);
                    }
                });
            },

            // Graphical Chart 
            updateSeriesGC() {
                return this.$store.dispatch(POST_API, {
                    data:{
                        site   	 : this.site ? this.site : null,
                        location : this.location ? this.location : null,
                        start    : moment(this.start).format('YYYY-MM-DD'),
                        end      : moment(this.end).format('YYYY-MM-DD'),
                        role     : this.$store.getters.currentUser.role,
                        om_sites : this.$store.getters.currentUser.om_sites,
                        report   : 'periodic',
                    },
                    api: '/api/feedback-request-graphical-charts'
                })
                .then(() => {
                    if (this.$store.getters.containsErrors) {
                        this.error_message = this.$store.getters.getErrors;
                    } else {
                        this.graphicalData = this.$store.getters.getResults.data
                    
                        return this.graphicalData
                    }
                });
            },
		},

		mounted(){
            this.site = this.$route.params.site;
            this.location = this.$route.params.location == 'null' ? null : this.$route.params.location;
            this.start = this.$route.params.start;
            this.end = this.$route.params.end;
            this.site_name = this.$route.params.site_name;
            this.location_name = this.$route.params.location_name;
            this.date_format = this.$route.params.date_format;
            this.chart_type = this.$route.params.chart_type.split(",");
            this.exportPDF();
		}
	
	}
</script>
<style scoped>
.dark-text{
    color: black !important;
}
.dark-layout .apexcharts-canvas .apexcharts-yaxis-label, .dark-layout .apexcharts-canvas .apexcharts-xaxis-label, .dark-layout .apexcharts-canvas .apexcharts-tooltip-text, .dark-layout .apexcharts-canvas .apexcharts-datalabel-label {
    fill: black!important;
}
apexcharts-legend-text {
    color: black !important;
}
</style>